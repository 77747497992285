import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IAshCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './ash-character-dynamic.scss';
import { Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faPeopleGroup,
  faReceipt,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { AshSkill } from '../modules/ash/components/ash-skill';
import { RatingBox } from '../modules/common/components/rating-box';
import { CategoryCard } from '../modules/cs/main/components/category-card';
import { AshProfileTeams } from '../modules/ash/profile/ash-teams';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IAshCharacterNodes {
  nodes: IAshCharacter[];
}

interface IAshCharacterEntry {
  currentUnit: IAshCharacterNodes;
}

interface IProps {
  data: IAshCharacterEntry;
}

const AshCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const fullImage = character.fullImage
    ? getImage(character.fullImage.localFile.childImageSharp)
    : null;

  const specialImage = character.specialImage
    ? getImage(character.specialImage.localFile.childImageSharp)
    : null;

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-ash'}
      game="ash"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small"></span>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>
              <span className="sub">Build and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background ${character.element} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={9}>
            <div className={`content-header ${character.element}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  is a{' '}
                  <strong className={`rarity-${character.rarity}`}>
                    {character.rarity}✦
                  </strong>{' '}
                  rarity Echomancer of the{' '}
                  <>
                    {character.class === 'Ranger' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_ranger.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Skirmisher' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_skirmisher.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Striker' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_striker.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Support' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_support.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Tactician' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_tactician.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                    {character.class === 'Vanguard' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/class_vanguard.webp"
                          alt={character.class}
                        />
                      </>
                    )}
                  </>{' '}
                  <strong className={`${character.class}`}>
                    {character.class}
                  </strong>{' '}
                  class who wields the{' '}
                  <>
                    {character.element === 'Corrosion' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_corrosion.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Fire' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_fire.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Ice' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_ice.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Lightning' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_lightning.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Physical' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_physical.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Water' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_water.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                    {character.element === 'Wind' && (
                      <>
                        <StaticImage
                          src="../images/ash/icons/ele_wind.webp"
                          alt={character.element}
                        />
                      </>
                    )}
                  </>{' '}
                  <strong className={`${character.element}`}>
                    {character.element}
                  </strong>{' '}
                  Element. The character was originally released on{' '}
                  <strong>{character.releaseDateCn}</strong> in China{' '}
                  {character.releaseDateGlobal ? (
                    <>
                      and they will release on{' '}
                      <strong>{character.releaseDateGlobal}</strong> in Global.
                    </>
                  ) : (
                    <>but their Global release date isn't known yet.</>
                  )}
                </h2>
                {character.introduction ? (
                  <>
                    <p>
                      {character.profileInfo
                        ? character.profileInfo.Intro
                        : '-'}
                    </p>
                  </>
                ) : (
                  ''
                )}
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
          <Col xs={12} xl={3}>
            <div className="video-promo">
              <div className={`content-header ${character.element}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Video guide
              </div>
              <div className="video-guide">
                <p>This character has no video guide yet.</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="last-updated">
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Last updated
          </div>
          <div className="info-box with-margin">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              profile was last updated on <strong>{character.updatedAt}</strong>
              .
            </p>
          </div>
        </div>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`${character.element}`}>{character.name}</strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Skills</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Tips & Dupes</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Teams' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Teams')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
            </div>
            <p>Teams</p>
          </div>
          <div
            className={`single-tab ${character.element} ${
              currentTab === 'Performance' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Performance')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faReceipt} width="18" />
            </div>
            <p>Profile & Tea Time</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Tags
          </div>
          <div className="specialities-list">
            {character.tags.Tag_1 != '' ? (
              <>
                <div className="employee-role">{character.tags.Tag_1}</div>
                {character.tags.Tag_2 && (
                  <div className="employee-role">{character.tags.Tag_2}</div>
                )}
                {character.tags.Tag_3 && (
                  <div className="employee-role">{character.tags.Tag_3}</div>
                )}
              </>
            ) : (
              <>
                <div className="info-box">
                  <p>
                    <strong className={`${character.element}`}>
                      {character.name}
                    </strong>{' '}
                    tags aren't available yet. They will be added soon!
                  </p>
                </div>
              </>
            )}
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          {character.cnOnly === true && (
            <>
              <div className="info-box with-margin">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  is only available in the CN version of the game currently. We
                  translated{' '}
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  skills ourselves, as such, there's a chance of localization
                  errors. We will add the official translations as soon as they
                  will be released. Please contact us directly to report any
                  errors.
                </p>
              </div>
            </>
          )}
          {character.skillIndicator ? (
            <>
              <div className={`skills ${character.element}`}>
                <Row xs={1} xl={2} xxl={2}>
                  <Col>
                    <div className="box">
                      <AshSkill
                        id={character.skillIndicator + '_Active_1'}
                        character_element={character.element}
                        type="Active"
                        maxLevel={12}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <AshSkill
                        id={character.skillIndicator + '_Active_2'}
                        character_element={character.element}
                        type="Active"
                        maxLevel={12}
                      />
                    </div>
                  </Col>
                </Row>
                <Row xs={1} xl={2} xxl={2}>
                  <Col>
                    <div className="box">
                      <AshSkill
                        id={character.skillIndicator + '_Seed'}
                        character_element={character.element}
                        type="Seed"
                        maxLevel={6}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <AshSkill
                        id={character.skillIndicator + '_Basic'}
                        character_element={character.element}
                        type="Basic"
                        maxLevel={1}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                skills aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Combat Traits (Dupes)
          </div>
          {character.skillIndicator ? (
            <>
              <div className={`skills ${character.element}`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <AshSkill
                        id={character.skillIndicator + '_Combat'}
                        character_element={character.element}
                        type="Combat"
                        maxLevel={1}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                combat traits aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Leader Skills
          </div>
          {character.skillIndicator ? (
            <>
              <div className={`skills ${character.element}`}>
                <Row xs={1} xl={2} xxl={2}>
                  <Col>
                    <div className="box">
                      <AshSkill
                        id={character.skillIndicator + '_Passive_1'}
                        character_element={character.element}
                        type="Passive"
                        maxLevel={1}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="box">
                      <AshSkill
                        id={character.skillIndicator + '_Passive_2'}
                        character_element={character.element}
                        type="Passive"
                        maxLevel={1}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                Engraving skills aren't available yet. They will be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Potential Exploration
          </div>
          <div className={`skills ${character.element}`}>
            <Row xs={1} xl={1} xxl={1}>
              <Col>
                <div className="box">
                  <div className="skill-header">
                    <div className={`skill-icon ${character.element}`}>
                      Potential Exploration
                    </div>
                    <div className="skill-info">
                      <p className="skill-name">Potential Exploration</p>
                    </div>
                  </div>
                  <div className={`potential`}>
                    <p>
                      <span>Lv. 0:</span> When set as Leader, this Echomancer
                      gains an Engraving Stat bonus of <b>20%</b> (including
                      VIT, ATK, and MST).
                    </p>
                    <p>
                      <span>Lv. 1:</span> When set as Leader, teammates gain an
                      Engraving Stat bonus of <b>20%</b> (including VIT, ATK,
                      and MST).
                    </p>
                    <p>
                      <span>Lv. 2:</span> When set as Leader and on the field:
                      Increases the VIT of Echomancers on the field by{' '}
                      <b>20%</b>.
                    </p>
                    <p>
                      <span>Lv. 3:</span> When set as Leader, this Echomancer’s
                      MST to DMG Rate is increased to <b>110%</b>.
                    </p>
                    {character.class === 'Bulwark' && (
                      <p>
                        <span>Lv. 4:</span> When set as Leader, each time this
                        Echomancer restores or consumes Block, the Extra DMG of
                        Echomancers on the field is increased by <b>60%</b> for{' '}
                        <b>30s</b>.
                      </p>
                    )}
                    {character.class === 'Tactician' && (
                      <p>
                        <span>Lv. 4:</span> When set as Leader, after this
                        Echomancer casts a debuff or buff skill, the Extra DMG
                        of Echomancers on the field is increased by <b>60%</b>{' '}
                        for <b>30s</b>.
                      </p>
                    )}
                    {character.class === 'Striker' && (
                      <p>
                        <span>Lv. 4:</span> When set as Leader, when this
                        Echomancer deals damage to boss or elite targets, the
                        Extra DMG of Echomancers on the field is increased by{' '}
                        <b>60%</b> for <b>30s</b>.
                      </p>
                    )}
                    {character.class === 'Support' && (
                      <p>
                        <span>Lv. 4:</span> When set as Leader, each time this
                        Echomancer heals, the healed target's Extra DMG is
                        increased by <b>60%</b> for <b>30s</b>.
                      </p>
                    )}
                    {character.class === 'Ranger' && (
                      <p>
                        <span>Lv. 4:</span> When set as Leader, after each use
                        of an Active Skill, the Extra DMG of Echomancers on the
                        field is increased by <b>20%</b>, stacking up to 3 times
                        and lasting for <b>30s</b>.
                      </p>
                    )}
                    {character.class === 'Skirmisher' && (
                      <p>
                        <span>Lv. 4:</span> When set as Leader, after this
                        Echomancer performs a combo, the Extra DMG of the
                        Echomancers on the field is increased by <b>60%</b>.
                      </p>
                    )}
                    {character.class === 'Vanguard' && (
                      <p>
                        <span>Lv. 4:</span> When set as Leader, each change in
                        the activation state of this Echomancer's Block ability
                        increases the Extra DMG of Echomancers on the field by{' '}
                        <b>60%</b> for <b>60s</b>.
                      </p>
                    )}
                    <p>
                      <span>Lv. 5:</span> ATK SPD <b>+5%</b>.
                    </p>
                    <p>
                      <span>Lv. 6:</span> CRIT Rate <b>+5%</b>.
                    </p>
                    {character.element === 'Fire' && (
                      <p>
                        <span>Lv. 7:</span> When set as Leader and on the field:{' '}
                        <u className="fire">Fire</u> Echomancers ignore{' '}
                        <b>10%</b> of the target's Basic DR. When an Elemental
                        Reaction is triggered on the field, applies a <b>40%</b>{' '}
                        [Elemental Reaction DMG Bonus] to all enemies for{' '}
                        <b>30s</b>.
                      </p>
                    )}
                    {character.element === 'Corrosion' && (
                      <p>
                        <span>Lv. 7:</span> When set as Leader on the field: For
                        each <u className="corrosion">Corrosion</u> Echomancer
                        on the field, Corrosion Blast DMG is increased by{' '}
                        <b>25%</b>, up to an increase of <b>50%</b>.
                      </p>
                    )}
                    {character.element === 'Wind' && (
                      <p>
                        <span>Lv. 7:</span> When set as Leader and on the field:
                        Increases the Engraving ATK of Echomancer on the field
                        by <b>25%</b>.
                      </p>
                    )}
                    {character.element === 'Ice' && (
                      <p>
                        <span>Lv. 7:</span> When set as Leader and on the field:{' '}
                        <u className="ice">Ice</u> Echomancers ignore <b>10%</b>{' '}
                        of the target's Basic DR. When an Elemental Reaction is
                        triggered on the field, applies a <b>40%</b> [Elemental
                        Reaction DMG Bonus] to all enemies for <b>30s</b>.
                      </p>
                    )}
                    {character.element === 'Physical' && (
                      <p>
                        <span>Lv. 7:</span> When set as Leader, this Echomancer
                        and their summons have a 35% chance to reduce the
                        target's <u className="physical">Physical RES</u> by
                        100%, up to a maximum reduction of <b>100%</b>, lasting{' '}
                        <b>15s</b>.
                      </p>
                    )}
                    {character.element === 'Water' && (
                      <p>
                        <span>Lv. 7:</span> When set as Leader and on the field:{' '}
                        <u className="water">Water</u> Echomancers ignore{' '}
                        <b>10%</b> of the target's Basic DR. When an Elemental
                        Reaction is triggered on the field, applies <b>40%</b>{' '}
                        [Elemental Reaction DMG Bonus] to all enemies for{' '}
                        <b>30s</b>.
                      </p>
                    )}
                    {character.element === 'Lightning' && (
                      <p>
                        <span>Lv. 7:</span> When set as Leader on the field:{' '}
                        <u className="lightning">Lightning</u> Echomancers
                        ignore <b>10%</b> of the target's Basic DR. When an
                        Elemental Reaction is triggered on the field, applies a{' '}
                        <b>40%</b>
                        [Elemental Reaction DMG Bonus] to all enemies for{' '}
                        <b>30s</b>.
                      </p>
                    )}
                    <p>
                      <span>Lv. 8:</span> After being switched to Backup, the
                      redeployment CD is reduced by <b>15s</b>.
                    </p>
                    <p>
                      <span>Lv. 9:</span> When set as Leader, the Extra DMG of
                      all Echomancers on the field is increased by <b>50%</b>.
                      Additionally, when calculating the number of Echomancers
                      required for [Inspire], this Echomancer is counted as{' '}
                      <b>2</b>.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ascension
          </div>
          <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              <strong>Ascension stats aren't available yet</strong>. They will
              be added soon!
            </p>
          </div>
          {/* <div className="info-box">
            <p>
              <strong className={`${character.element}`}>
                {character.name}
              </strong>{' '}
              potential exploration aren't available yet. They will be added
              soon!
            </p>
          </div> */}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <Row xs={1} xl={2} className={`char-gallery`}>
            <Col>
              <div className="box">
                <h5 className={` ${character.element}`}>S0</h5>
                {fullImage ? (
                  <GatsbyImage
                    image={fullImage}
                    alt=""
                    className="full-image"
                  />
                ) : (
                  <p>S0 image for this character isn't available yet.</p>
                )}
              </div>
            </Col>
            <Col>
              <div className="box">
                <h5 className={`${character.element}`}>S4</h5>
                {specialImage ? (
                  <GatsbyImage
                    image={specialImage}
                    alt=""
                    className="full-image"
                  />
                ) : (
                  <p>
                    {character.rarity === '5' || character.rarity === '6' ? (
                      <>S4 image for this character isn't available yet.</>
                    ) : (
                      <>Only 5 and 6 star characters have S4 image.</>
                    )}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faBook} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Intro
          </div>
          {character.introduction ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.introduction && (
                  <>{renderRichText(character.introduction, options)}</>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box with-margin">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>introduction isn't available yet.</strong> It will be
                added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {character.ratings.cn != 1 ? (
            <>
              <div className="ratings-container">
                <RatingBox
                  game="afk"
                  rating={character.ratings.global}
                  ratingName="Global Server"
                />
                <RatingBox
                  game="afk"
                  rating={character.ratings.cn}
                  ratingName="CN Server"
                />
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>ratings aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          {character.tierComment ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                <h6>Tier list comment</h6>
                {character.tierComment && (
                  <>{renderRichText(character.tierComment, options)}</>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>tier list comment isn't available yet.</strong> It will
                be added soon!
              </p>
            </div>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon!
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
              Tips & Dupes
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Tips & Dupes
          </div>
          {character.tipsAndDupes ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.tipsAndDupes, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`${character.element}`}>
                  {character.name}
                </strong>{' '}
                <strong>Tips & Dupes tips aren't available yet</strong>. They
                will be added soon!
              </p>
            </div>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Teams' ? 'active' : ''}`}>
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faPeopleGroup} width="18" />
              Teams
            </p>
          </div>
          {(character.slug === 'scarlett' ||
            character.slug === 'cen-ying' ||
            character.slug === 'lorelle') && (
            <AshProfileTeams
              slug={character.slug}
              element={character.element}
            />
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Teams
          </div>
          <p>You can find our dedicated Team Building guide by going here:</p>
          <Row
            xs={1}
            md={2}
            lg={2}
            xxl={2}
            className="g-3 category-cards-container"
          >
            <CategoryCard
              title="Team Building"
              link="/ash-echoes/guides/team-building"
              image={
                <StaticImage
                  src="../images/ash/categories/category_teams.webp"
                  alt="Team Building"
                />
              }
            />
            {character.slug === 'lorelle' && (
              <CategoryCard
                title="SYP Lorelle"
                link="/ash-echoes/guides/should-you-pull-lorelle"
                image={
                  <StaticImage
                    src="../images/ash/categories/category_lorelle.webp"
                    alt="SYP Lorelle"
                  />
                }
              />
            )}
            {character.slug === 'cen-ying' && (
              <CategoryCard
                title="SYP Cen Ying"
                link="/ash-echoes/guides/should-you-pull-cen-ying"
                image={
                  <StaticImage
                    src="../images/ash/categories/category_cen.webp"
                    alt="SYP Cen Ying"
                  />
                }
              />
            )}
          </Row>
        </div>
        <div
          className={`tab-inside ${
            currentTab === 'Performance' ? 'active' : ''
          }`}
        >
          <div className={`mobile-header ${character.element}`}>
            <p>
              <FontAwesomeIcon icon={faReceipt} width="18" />
              Profile & Tea Time
            </p>
          </div>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Profile
          </div>
          {character.profileInfo ? (
            <>
              <Row xs={1} xxl={3} className="va-section">
                <Col>
                  <div className="info-list-row">
                    <div className="category">Name</div>
                    <div className="details">
                      {character.profileInfo.Name
                        ? character.profileInfo.Name
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">World</div>
                    <div className="details">
                      {character.profileInfo.World
                        ? character.profileInfo.World
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Affiliation</div>
                    <div className="details">
                      {character.profileInfo.Affiliation
                        ? character.profileInfo.Affiliation
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Gender</div>
                    <div className="details">
                      {character.profileInfo.Gender
                        ? character.profileInfo.Gender
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Height</div>
                    <div className="details">
                      {character.profileInfo.Height
                        ? character.profileInfo.Height
                        : '-'}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="info-list-row">
                    <div className="category">Birthday</div>
                    <div className="details">
                      {character.profileInfo.Birthday
                        ? character.profileInfo.Birthday
                        : '-'}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>profile information aren't available yet</strong>.
                  They will be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> CV
          </div>
          <Row xs={1} xxl={2} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">CN</div>
                <div className="details">
                  {character.profileInfo.CV_CN
                    ? character.profileInfo.CV_CN
                    : '-'}
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">JPN</div>
                <div className="details">
                  {character.profileInfo.CV_JPN
                    ? character.profileInfo.CV_JPN
                    : '-'}
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header ${character.element}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Tea Time
          </div>
          <p>
            Any Formula requires a minimum of 330 score in order to unlock new
            Dialogues. Full Bonus can be achieved with Coziness 4000 + Bond
            level 9 or above. There are two types of Formula:{' '}
            <strong>Memoir and Score</strong>. "Memoir" enables the Memoir book,
            while "Score" are high score recipes used to farm bond (the numbers
            in the brackets mean: No Bonus/Full Coziness/Full Bonus).
          </p>
          {character.teaTime ? (
            <div className="tea-time">
              <div className="tea-time-row header">
                <div className="team-time-column">Bond Level</div>
                <div className="team-time-column">Achievement Name</div>
                <div className="team-time-column">Formula</div>
                <div className="team-time-column">Type</div>
              </div>
              {character.teaTime
                .sort((a, b) => (b.order < a.order ? 1 : -1))
                .map((bond, index) => {
                  return (
                    <div className="tea-time-row" key={index}>
                      <div className="team-time-column level">
                        [{bond.level}]
                      </div>
                      <div className="team-time-column">
                        <span className="on-mobile block">
                          Bond Level & Name
                        </span>
                        <span className="on-mobile">[{bond.level}]</span>{' '}
                        {bond.name}
                      </div>
                      <div className="team-time-column">
                        <span className="on-mobile block">Formula</span>{' '}
                        {bond.formula}
                      </div>
                      <div className="team-time-column">
                        <span className="on-mobile block">Type</span>
                        {bond.score === '-' ? (
                          'Memoir'
                        ) : (
                          <>Score ({bond.score})</>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`${character.element}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>Tea Time information aren't available yet</strong>.
                  They will be added soon!
                </p>
              </div>
            </>
          )}
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default AshCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Ash Echoes | Prydwen Institute'}
      description={
        character.name +
        ' from Ash Echoes. Find the best builds, teams, synergy, and other information here.'
      }
      image={character.fullImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulAshCharacter(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...AshCharacterFieldsFragment
      }
    }
  }
`;
