import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';
import { AshCharacter } from '../components/ash-character';
import { AshTrace } from '../components/ash-trace';

interface IProps {
  slug: string;
  element: string;
}

export const AshProfileTeams: React.FC<IProps> = ({ slug, element }) => {
  return (
    <>
      <div className={`content-header  ${element}`}>
        <FontAwesomeIcon icon={faSquare} width="18" /> Detailed teams guide
      </div>
      {slug === 'scarlett' && (
        <>
          <div className={`character-teams box Wind`}>
            <div className="team-header">
              <p>RELENTLESS ATTACKS</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="scarlett"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="shiratori-azusa"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Sub-DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A very aggressive but self-reliant team. It maximizes damage
            potential from all fronts, with Scarlett reducing enemy DR, Azusa
            reducing enemy’s Elemental RES and Begonia increasing the team’s
            ASPD. Freda adds the cherry on the cake with her myriad of buffs and
            healing. During most of the fight, Azusa and Begonia should split
            the frontline to make managing damage easier. When dealing with
            elite enemies or bosses, Scarlett takes the frontline instead, by
            activating her skill “Relentless Pursuit”.
          </p>
          <h5>Leader: Scarlett</h5>
          <div className={`character-teams box Wind`}>
            <div className="team-header">
              <p>DEFENSIVE PURSUIT</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="scarlett"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="shiratori-azusa"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="longqing"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A well-balanced team with a solid frontline and a more defensive
            approach. In this team, instead of focusing on ASPD, Lorelle will
            provide Extra DMG bonus and block gauge breaking through her
            Polyphony passive, while Longqing can quickly stack Inspire with
            “Nightmare”. She can be switched to Freda after reaching full
            Inspire stacks for more ATK buffs.
          </p>
          <h5>Leader: Scarlett</h5>
        </>
      )}
      {slug === 'cen-ying' && (
        <>
          <div className={`character-teams box Physical`}>
            <div className="team-header">
              <p>SUMMONER TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="luke"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="mo-hongxiu"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A straightforward team. Cen Ying and Luke are obvious picks and need
            no introduction. Mo Hongxiu can create barriers, displace and group
            up enemies, helping to control the battlefield and direct burst
            windows.Freda is another easy pick for her buffs and ability to
            lower her teammates’ cooldowns, which helps with Cen Ying’s problem
            of having a long cooldown on her Seed Skill.
          </p>
          <div className={`character-teams box Physical`}>
            <div className="team-header">
              <p>ALT. SUMMONER TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="sephonis"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="longqing"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Lorelle has the ability to draw DMG Taken to herself while on
            Defense Mode, and buff all allies attacks when in Attack mode. She’s
            also useful for her gauge breaking capability, something the
            Summoner team usually struggles with. She can be paired with
            Sephonis. As a fellow summoner and Corrosion Echomancer, Sephonis
            benefits from most traces in the build and synergizes with Lorelle.
            If you’re pulling for Cen Ying, chances are you’ll get at least one
            copy of her.
          </p>
          <div className={`character-teams box Physical`}>
            <div className="team-header">
              <p>BUDGET / NECROMANCER TEAM</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="cen-ying"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="gauya"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="tian-ruo"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="su-xiao"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            The usage of this team match is that Cen Ying stands in the front
            line to die once; Su Xiao can directly resurrect and then buff her
            ATK and CRIT until the end of the battle. Gauya's “Soul Queller" is
            considered a summon, and he’s also able to temporarily summon a
            ghost of an ally who died to fight.
          </p>
        </>
      )}
      {slug === 'lorelle' && (
        <>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>FULL CORROSION</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="caroline"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="gina"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Our main team has <strong>Lorelle</strong> as Tank, increasing the
            team's survivability and damage potential, Caroline as DPS with some
            healing on the side, and <strong>Gina</strong> for Burst Damage and
            Anti-Projectile capabilities. All three provide Corrosion
            Accumulation which increases the team's damage output. Freda
            provides consistent healing and buffs. <strong>Aceo</strong> and{' '}
            <strong>Sephonis</strong> can be used as backups or switch in with
            Gina in case you don’t have her.
          </p>
          <h5>Leader: Caroline</h5>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul>
            <li>
              <AshTrace name="Hidden Blade" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Shadows Of The Alley" mode="data" onProfile /> or{' '}
              <AshTrace name="Nightmare" mode="data" onProfile /> (if with
              Longqing) or <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Muscle Building" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile /> or{' '}
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Illusion of Flowers" mode="data" onProfile /> or{' '}
              <AshTrace name="Karma" mode="data" onProfile /> if with Lorelle as
              Leader
            </li>
          </ul>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>ICE WITH SPICE</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="ivana"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lydia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Elemental Reaction</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            Better suited for [Wet Environment]s, this team is a proof of
            concept of Lorelle’s versatility and ability to enable all sorts of
            team compositions. Begonia’s ASPD buffs greatly enhance Ivana’s and
            Lydia’s performances, both heavily tied to their auto-attacks. With
            Lorelle diverting 30% of damage and Lydia easily triggering
            Electrolyzed, this team can survive just fine without a healer while
            dishing lots of damage. You can also opt to swap Begonia or Lydia
            for <strong>Sambheka</strong> to enable the team in non-Wet
            environments, and always bring a healer as backup in case things go
            south.{' '}
            <strong>
              If you don’t feel secure, using a healer from the start and
              treating this as a Freeze team is also a valid approach.
            </strong>
          </p>
          <h5>Leader: Ivana</h5>
          <p>
            <strong>Memory Traces</strong>
          </p>
          <ul>
            <li>
              <AshTrace name="Shadows of the Alley" mode="data" onProfile /> or{' '}
              <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
            </li>
            <li>
              <AshTrace name="Traces in the Museum" mode="data" onProfile /> or{' '}
              <AshTrace name="Beneath the Deception" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Emptiness" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Hello, Visier" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Growth" mode="data" onProfile />
            </li>
          </ul>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>GOTTA GO FAST</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="begonia"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="felicio"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Sub DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="freda"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A Beginner friendly team fully focused on getting as much ASPD and
            Cooldown reduction as possible. These four characters all stack
            buffs from ATK to CRIT to Extra DMG to the aforementioned ASPD and
            Cooldown reduction, and are all focused on auto-attack. That makes
            this team very forgiving for beginners who will still maintain their
            output even if their run out of skill uses. Aside from that, the
            team has everything covered, from block gauge breaking to
            interception of projectiles and Airborne enemies.
          </p>
          <div className={`character-teams box Corrosion`}>
            <div className="team-header">
              <p>BUDGET EXPLOSION</p>
            </div>
            <div className="single-team">
              <div className="team-row">
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="ms-lew"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Main DPS</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="reid"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role dps">Fire Zone</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="lorelle"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role tank">Tank</div>
                </div>
                <div className="with-role">
                  <div className="character main">
                    <Card className="avatar-card">
                      <AshCharacter
                        slug="su-xiao"
                        mode="card"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </Card>
                  </div>
                  <div className="role support">Support</div>
                </div>
              </div>
            </div>
          </div>
          <p>
            A team composed mainly of free characters and Memory Traces +
            Lorelle. It’s nevertheless powerful enough to carry you all the way
            through the game’s main content, and is also a very forgiving team
            for players who are learning the game. Reid can heal himself fully
            once per battle, and Su Xiao can save an ally from death once per
            battle as well (twice once she gets her third dupe). As you progress
            through the game, you can upgrade your team comp with higher rarity
            characters that will perform the same functions but with higher
            output, such as Lydia, Hassel, Cyros and Freda.
          </p>
          <ul>
            <li>
              <AshTrace name="Happy Hours" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Codename Diversity" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Beneath the Deception" mode="data" onProfile /> or{' '}
              <AshTrace name="Into a Paper Crane" mode="data" onProfile />{' '}
              (“Gotta go Fast” team) or{' '}
              <AshTrace name="Deadline" mode="data" onProfile /> (“Budget
              Explosion” team)
            </li>
            <li>
              <AshTrace name="Behind the Curtains" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Karma" mode="data" onProfile />
            </li>
            <li>
              <AshTrace name="Blows? Bonds?" mode="data" onProfile />
            </li>
          </ul>
        </>
      )}
    </>
  );
};
